<template>
  <div ref="preloader" class="preloader">
    <svg width="135" height="40" viewBox="0 0 135 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.9088 19.774L14.2263 6.0916C14.1663 6.0325 14.0854 5.99939 14.0011 5.99939C13.9168 5.99939 13.8359 6.0325 13.7758 6.0916L0.0934171 19.7777C0.0638282 19.8069 0.0403336 19.8417 0.0242981 19.88C0.00826268 19.9184 0 19.9595 0 20.0011C0 20.0427 0.00826268 20.0838 0.0242981 20.1222C0.0403336 20.1605 0.0638282 20.1953 0.0934171 20.2245L13.7758 33.9069C13.8053 33.9366 13.8404 33.9602 13.8791 33.9763C13.9178 33.9924 13.9592 34.0007 14.0011 34.0007C14.043 34.0007 14.0844 33.9924 14.1231 33.9763C14.1618 33.9602 14.1968 33.9366 14.2263 33.9069L27.9088 20.2245C27.9385 20.195 27.962 20.1599 27.9781 20.1213C27.9942 20.0826 28.0025 20.0411 28.0025 19.9993C28.0025 19.9574 27.9942 19.9159 27.9781 19.8773C27.962 19.8386 27.9385 19.8035 27.9088 19.774ZM21.5052 23.6221C21.5051 23.6587 21.496 23.6947 21.4786 23.7269C21.4612 23.7592 21.436 23.7866 21.4055 23.8067C21.3698 23.8283 21.329 23.8398 21.2873 23.8399C21.254 23.8401 21.2211 23.8325 21.1913 23.8178L13.9826 20.2208L6.79613 23.8178C6.76483 23.8321 6.73084 23.8397 6.69641 23.8399C6.65943 23.8333 6.62497 23.8167 6.59671 23.7919C6.56496 23.7727 6.53874 23.7456 6.52061 23.7133C6.50249 23.6809 6.49308 23.6444 6.49331 23.6073V16.3728C6.49308 16.3357 6.50249 16.2992 6.52061 16.2668C6.53874 16.2344 6.56496 16.2073 6.59671 16.1881C6.6295 16.17 6.66635 16.1605 6.70381 16.1605C6.74127 16.1605 6.7781 16.17 6.8109 16.1881L13.9826 19.774L21.1765 16.1771C21.2097 16.1607 21.2466 16.1532 21.2835 16.155C21.3205 16.1569 21.3564 16.1682 21.3878 16.1878C21.4192 16.2073 21.4451 16.2346 21.4631 16.267C21.481 16.2994 21.4904 16.3358 21.4904 16.3728L21.5052 23.6221Z"
        fill="#23C172"
      />
      <path
        d="M101.059 24.3164C100.891 24.3212 100.724 24.2908 100.569 24.2272C100.414 24.1636 100.274 24.0682 100.158 23.9471C100.038 23.8284 99.9434 23.6868 99.88 23.5306C99.8165 23.3745 99.7853 23.2071 99.7883 23.0386C99.7863 22.873 99.8181 22.7087 99.8815 22.5557C99.945 22.4027 100.039 22.2642 100.158 22.1486C100.274 22.0271 100.414 21.9304 100.569 21.8643C100.724 21.7982 100.89 21.7642 101.059 21.7642C101.227 21.7642 101.394 21.7982 101.548 21.8643C101.703 21.9304 101.843 22.0271 101.96 22.1486C102.078 22.2647 102.171 22.4033 102.235 22.5562C102.298 22.709 102.33 22.8731 102.329 23.0386C102.331 23.207 102.3 23.3741 102.236 23.5301C102.173 23.6862 102.079 23.8279 101.96 23.9471C101.843 24.0678 101.703 24.163 101.548 24.2266C101.393 24.2902 101.226 24.3207 101.059 24.3164Z"
        fill="#23C172"
      />
      <path
        d="M37.9427 24.3016C37.4496 24.31 36.9599 24.2196 36.5024 24.0357C35.7503 23.7337 35.1273 23.1786 34.7409 22.4662C34.5749 22.1774 34.4506 21.8666 34.3716 21.543L36.2845 20.8265C36.3717 21.2409 36.5799 21.6201 36.8828 21.916C37.0309 22.0528 37.2049 22.1586 37.3945 22.2271C37.5842 22.2956 37.7856 22.3254 37.987 22.3148C38.3032 22.3151 38.6149 22.2391 38.8955 22.0932C39.1953 21.9302 39.4378 21.6788 39.5897 21.3731C39.7725 20.9743 39.8561 20.5372 39.8335 20.099V12.9199H42.0012V20.7305C42.0083 21.239 41.905 21.743 41.6984 22.2077C41.5011 22.6418 41.2097 23.0266 40.8453 23.3341C40.4629 23.6496 40.0244 23.8901 39.5528 24.0431C39.0333 24.2147 38.4897 24.302 37.9427 24.3016Z"
        fill="white"
      />
      <path
        d="M47.6991 24.2683C46.9667 24.2831 46.244 24.098 45.6089 23.7328C45.0064 23.3816 44.513 22.8703 44.1834 22.2556C43.8293 21.5889 43.6513 20.8427 43.6664 20.0879C43.6515 19.3312 43.8348 18.5839 44.1982 17.9201C44.5471 17.3025 45.0578 16.7918 45.6754 16.4429C46.331 16.0787 47.0711 15.894 47.821 15.9074C48.5003 15.905 49.1664 16.0959 49.7413 16.4577C50.3552 16.8535 50.8484 17.4105 51.1668 18.0678C51.5511 18.874 51.7372 19.7602 51.7097 20.6529H49.5751C49.6022 20.0843 49.5284 19.5155 49.3573 18.9726C49.2454 18.609 49.0203 18.2907 48.7147 18.0641C48.4254 17.8745 48.0856 17.7767 47.7397 17.7834C47.3912 17.7729 47.0468 17.8612 46.7463 18.0383C46.4623 18.2224 46.2469 18.4949 46.1333 18.8138C45.9783 19.2457 45.9069 19.7031 45.9228 20.1617C45.9086 20.5763 46.0028 20.9874 46.1961 21.3545C46.365 21.6675 46.6218 21.9242 46.9347 22.0931C47.2444 22.2557 47.5894 22.3394 47.9392 22.3369C48.2744 22.3576 48.608 22.2757 48.8956 22.1022C49.1832 21.9287 49.4112 21.6718 49.5493 21.3656L51.525 22.1301C51.3024 22.5631 51.0062 22.9542 50.6498 23.286C50.2858 23.6168 49.857 23.8684 49.3905 24.0245C48.8446 24.2009 48.2726 24.2833 47.6991 24.2683ZM45.0365 20.6492V19.0095H50.6461V20.6492H45.0365Z"
        fill="white"
      />
      <path
        d="M52.5259 17.7983V16.1069H57.9287V17.7983H52.5259ZM53.8517 24.0763V15.2982C53.805 14.5764 54.0392 13.8645 54.5053 13.3113C54.9411 12.8386 55.6132 12.6023 56.4958 12.6023C56.8292 12.6074 57.1601 12.6609 57.4781 12.7611C57.804 12.8547 58.1205 12.9784 58.4235 13.1304L57.6443 14.6445C57.4961 14.5676 57.3417 14.5033 57.1827 14.4525C57.063 14.4149 56.9388 14.3938 56.8134 14.3897C56.6907 14.3819 56.5681 14.4048 56.4565 14.4564C56.3449 14.508 56.248 14.5866 56.1745 14.6851C56.015 14.9734 55.9431 15.302 55.9677 15.6305V24.0468L53.8517 24.0763Z"
        fill="white"
      />
      <path
        d="M58.793 17.7983V16.1069H64.1773V17.7983H58.793ZM60.115 24.0763V15.2982C60.0703 14.5766 60.3043 13.8654 60.7687 13.3113C61.2045 12.8386 61.8766 12.6023 62.7592 12.6023C63.0926 12.6074 63.4235 12.6609 63.7415 12.7611C64.0674 12.8547 64.3839 12.9784 64.6869 13.1304L63.9077 14.6445C63.7595 14.5676 63.6051 14.5033 63.4461 14.4525C63.3264 14.4149 63.2022 14.3938 63.0768 14.3897C62.9544 14.3801 62.8317 14.4022 62.7204 14.454C62.6091 14.5057 62.5131 14.5854 62.4416 14.6851C62.2789 14.9723 62.2056 15.3015 62.2311 15.6305V24.0468L60.115 24.0763Z"
        fill="white"
      />
      <path
        d="M68.3872 24.0763L64.7349 16.1069H67.0467L69.7093 22.577L70.3925 24.0763H68.3872ZM67.1611 27.4554C66.8381 27.4495 66.5179 27.3935 66.212 27.2892C65.8945 27.1894 65.5878 27.0583 65.2962 26.8978L66.0754 25.1916C66.2379 25.2794 66.4056 25.3571 66.5776 25.4243C66.7066 25.4781 66.8442 25.5081 66.9839 25.5129C67.1956 25.5164 67.4027 25.4516 67.5747 25.3282C67.7577 25.1896 67.9005 25.0048 67.9884 24.7928L68.9301 22.577L71.4634 16.1069H73.8085L70.1894 24.7632C69.989 25.2494 69.7545 25.7208 69.4877 26.1739C69.2669 26.5573 68.958 26.8826 68.5866 27.123C68.1534 27.3688 67.6584 27.4843 67.1611 27.4554Z"
        fill="white"
      />
      <path
        d="M75.064 24.0763V12.9199H77.1985V24.0763H75.064ZM75.8616 19.3604V17.4622H78.8899C79.2993 17.4837 79.7022 17.3521 80.0199 17.0929C80.161 16.9611 80.2713 16.7997 80.3428 16.6203C80.4143 16.4409 80.4453 16.248 80.4335 16.0552C80.4441 15.8575 80.4078 15.6601 80.3276 15.4791C80.2474 15.2981 80.1255 15.1387 79.9719 15.0138C79.6106 14.7425 79.1632 14.6113 78.7126 14.6445H75.8616V12.9347L78.8419 12.9051C79.5294 12.8939 80.2123 13.0194 80.8508 13.2744C81.3928 13.489 81.8584 13.8606 82.1877 14.3417C82.5214 14.8671 82.6887 15.4811 82.6678 16.1032C82.6668 16.677 82.4598 17.2314 82.0843 17.6653C81.6436 18.1645 81.085 18.5453 80.4594 18.7732C79.6828 19.0635 78.8583 19.2039 78.0294 19.1868L75.8616 19.3604ZM75.9392 24.0763V22.1486H79.1447C79.5878 22.1752 80.025 22.0356 80.3707 21.7571C80.5188 21.6284 80.6359 21.4678 80.7132 21.2875C80.7906 21.1072 80.8263 20.9117 80.8176 20.7157C80.826 20.5086 80.7856 20.3024 80.6996 20.1138C80.6136 19.9252 80.4845 19.7595 80.3227 19.63C79.9286 19.3511 79.4486 19.2203 78.9674 19.2607H75.9392V17.573L77.8706 17.6062C78.7896 17.5907 79.706 17.7063 80.5923 17.9497C81.283 18.1241 81.9103 18.4902 82.4019 19.0059C82.8507 19.5335 83.0818 20.2122 83.0481 20.9041C83.0664 21.5152 82.906 22.1184 82.5865 22.6397C82.275 23.1237 81.8244 23.5021 81.294 23.7255C80.6794 23.9829 80.0177 24.1087 79.3515 24.0948L75.9392 24.0763Z"
        fill="#23C172"
      />
      <path
        d="M88.2441 24.2684C87.5129 24.2831 86.7914 24.098 86.1576 23.7329C85.5551 23.3817 85.0617 22.8704 84.7321 22.2557C84.375 21.59 84.1956 20.8433 84.2114 20.088C84.1958 19.3308 84.3806 18.583 84.7469 17.9202C85.0947 17.3018 85.6057 16.7908 86.2241 16.443C86.8784 16.079 87.6173 15.8942 88.366 15.9075C89.0454 15.9045 89.7116 16.0954 90.2863 16.4578C90.9013 16.8533 91.3957 17.4103 91.7155 18.0679C92.0968 18.875 92.2815 19.7608 92.2547 20.653H90.1201C90.15 20.0846 90.0775 19.5154 89.906 18.9727C89.7929 18.6087 89.5665 18.2904 89.2597 18.0642C88.9715 17.8751 88.6331 17.7773 88.2885 17.7836C87.9388 17.7735 87.5933 17.8618 87.2913 18.0384C87.0083 18.2235 86.7931 18.4957 86.6783 18.8139C86.5248 19.2461 86.4546 19.7035 86.4715 20.1618C86.4562 20.5761 86.5491 20.9872 86.7411 21.3546C86.9093 21.6681 87.1662 21.925 87.4797 22.0932C87.7892 22.2563 88.1343 22.34 88.4842 22.337C88.8411 22.3532 89.1944 22.259 89.496 22.0674C89.7526 21.89 89.9578 21.6479 90.0906 21.3657L92.07 22.1302C91.8449 22.5626 91.5476 22.9535 91.1911 23.2861C90.8277 23.6178 90.3987 23.8694 89.9318 24.0247C89.387 24.2006 88.8164 24.283 88.2441 24.2684ZM85.5815 20.6493V19.0096H91.1911V20.6493H85.5815Z"
        fill="#23C172"
      />
      <path
        d="M93.0747 17.8832V16.0958H98.4627V17.8832H93.0747ZM97.0409 24.2572C96.315 24.3103 95.5973 24.0755 95.0431 23.6035C94.8095 23.3586 94.6288 23.0684 94.5119 22.7508C94.395 22.4332 94.3445 22.095 94.3635 21.7571V13.6326H96.4685V21.2991C96.4488 21.5745 96.5273 21.848 96.6901 22.071C96.7723 22.1611 96.8737 22.2316 96.9869 22.277C97.1001 22.3224 97.2221 22.3416 97.3438 22.3332C97.464 22.3314 97.5829 22.3076 97.6946 22.263C97.8362 22.209 97.9702 22.137 98.0934 22.0488L98.832 23.6257C98.5707 23.8207 98.2836 23.9786 97.979 24.0947C97.6798 24.2103 97.3617 24.2691 97.0409 24.2683V24.2572Z"
        fill="#23C172"
      />
      <path
        d="M107.865 24.2683C107.092 24.2832 106.329 24.0987 105.649 23.7328C105.015 23.3928 104.49 22.8808 104.135 22.2556C103.779 21.5899 103.592 20.8466 103.592 20.0915C103.592 19.3365 103.779 18.5932 104.135 17.9275C104.484 17.3099 104.995 16.7992 105.612 16.4503C106.292 16.0831 107.055 15.8986 107.828 15.9148C108.596 15.9079 109.351 16.1123 110.01 16.5057C110.667 16.8972 111.173 17.4981 111.447 18.2118L109.471 18.9135C109.317 18.6047 109.076 18.3479 108.777 18.1749C108.46 17.9893 108.099 17.8936 107.732 17.8979C107.368 17.8902 107.009 17.9862 106.698 18.1749C106.398 18.3634 106.155 18.6303 105.996 18.9467C105.82 19.3036 105.733 19.6975 105.741 20.0952C105.732 20.4907 105.82 20.8824 105.996 21.2363C106.163 21.5604 106.417 21.8314 106.729 22.0187C107.042 22.206 107.401 22.3021 107.765 22.2962C108.135 22.3018 108.498 22.1963 108.807 21.9934C109.111 21.8009 109.352 21.5236 109.501 21.1957L111.495 21.8974C111.21 22.6193 110.705 23.2331 110.051 23.6515C109.397 24.0633 108.638 24.2775 107.865 24.2683Z"
        fill="#23C172"
      />
      <path
        d="M116.647 24.2683C115.887 24.2835 115.137 24.0988 114.472 23.7328C113.853 23.3858 113.342 22.8746 112.994 22.2557C112.623 21.5945 112.436 20.846 112.452 20.0879C112.437 19.3313 112.62 18.5839 112.983 17.9201C113.331 17.3016 113.842 16.7906 114.461 16.4429C115.13 16.0913 115.876 15.9076 116.632 15.9076C117.389 15.9076 118.134 16.0913 118.803 16.4429C119.423 16.7895 119.934 17.3008 120.281 17.9201C120.632 18.5886 120.816 19.3326 120.816 20.0879C120.816 20.8432 120.632 21.5872 120.281 22.2557C119.935 22.8757 119.424 23.3872 118.803 23.7328C118.145 24.0997 117.401 24.2846 116.647 24.2683ZM116.647 22.2113C116.993 22.2189 117.335 22.1252 117.629 21.9417C117.912 21.7597 118.14 21.5045 118.29 21.2032C118.456 20.8579 118.538 20.4783 118.53 20.0953C118.537 19.7115 118.453 19.3316 118.283 18.9874C118.128 18.6839 117.893 18.4286 117.603 18.2488C117.308 18.0642 116.965 17.9692 116.617 17.9755C116.274 17.9706 115.936 18.0655 115.646 18.2488C115.357 18.4269 115.124 18.6829 114.974 18.9874C114.816 19.3354 114.734 19.7131 114.734 20.0953C114.734 20.4774 114.816 20.8552 114.974 21.2032C115.126 21.5086 115.362 21.7647 115.653 21.9417C115.952 22.124 116.297 22.2175 116.647 22.2113Z"
        fill="#23C172"
      />
      <path
        d="M122.305 24.0763V16.1069H124.28L124.406 17.5841V24.0873L122.305 24.0763ZM127.578 20.014C127.597 19.5867 127.535 19.1596 127.397 18.7547C127.301 18.4882 127.117 18.2618 126.877 18.1121C126.64 17.9749 126.371 17.9058 126.097 17.9127C125.865 17.8935 125.631 17.9277 125.414 18.0129C125.197 18.098 125.003 18.2318 124.845 18.4039C124.523 18.8399 124.367 19.377 124.406 19.918H123.708C123.685 19.1816 123.811 18.4482 124.077 17.7613C124.295 17.2173 124.664 16.747 125.141 16.406C125.604 16.0863 126.155 15.9209 126.718 15.9333C127.13 15.9081 127.542 15.9745 127.925 16.1278C128.308 16.281 128.653 16.5173 128.934 16.8196C129.451 17.4105 129.697 18.3165 129.672 19.5376L127.578 20.014ZM127.578 24.0763V20.014L129.683 19.5376V24.0947L127.578 24.0763ZM132.87 20.014C132.889 19.5861 132.825 19.1586 132.682 18.7547C132.58 18.491 132.398 18.2661 132.161 18.1121C131.923 17.9735 131.65 17.9045 131.375 17.9127C131.142 17.8935 130.909 17.9277 130.692 18.0129C130.475 18.098 130.28 18.2318 130.123 18.4039C129.799 18.8392 129.643 19.3768 129.683 19.918H128.982C128.959 19.1816 129.084 18.4481 129.351 17.7613C129.562 17.2178 129.927 16.7471 130.4 16.406C130.867 16.0872 131.422 15.922 131.988 15.9333C132.4 15.9063 132.813 15.9718 133.196 16.1252C133.579 16.2786 133.924 16.5158 134.203 16.8196C134.713 17.4105 134.968 18.3165 134.968 19.5376L132.87 20.014ZM132.87 24.0763V20.014L134.975 19.5376V24.0947L132.87 24.0763Z"
        fill="#23C172"
      />
    </svg>
  </div>
</template>

<style src="~/assets/styles/components/atomic/preloader.scss" lang="scss" />
